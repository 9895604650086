import {getUseragentInfo} from "@mp-npm/useragent-info";

function redirectToHmsAppLinking({href, search, linkEntry}) {
    const searchParams = new URLSearchParams(search);
    const hmsSearchParams = new URLSearchParams();
    hmsSearchParams.append('deeplink', searchParams.get('link'));
    hmsSearchParams.append('android_deeplink', href);
    hmsSearchParams.append('android_fallback_url', searchParams.get('afl'));
    hmsSearchParams.append('android_package_name', searchParams.get('apn'));
    hmsSearchParams.append('ios_bundle_id', searchParams.get('ibi'));
    hmsSearchParams.append('ios_fallback_url', searchParams.get('ifl'));
    window.location = `${linkEntry}?${hmsSearchParams}`;
}

function redirectToGmsDynamicLinks({search, linkEntry}) {
    window.location = `${linkEntry}${search}`;
}

function redirectToMainWeb({search}) {
    const params = new URLSearchParams(search);
    const link = params.get('ofl') || params.get('link')
    if (link) {
        window.location = link;
    }
}

function detectFramework({linkFirebaseEntry, linkHuaweiEntry}) {
    const {
        isDesktopDevice,
        isUntypedMobileDevice,
        isHmsSupported,
    } = getUseragentInfo();

    if (isDesktopDevice || isUntypedMobileDevice) {
        redirectToMainWeb(window.location);
        return;
    }

    if (isHmsSupported) {
        redirectToHmsAppLinking({
            href: window.location.href,
            search: window.location.search,
            linkEntry: linkHuaweiEntry,
        });
    } else {
        redirectToGmsDynamicLinks({
            search: window.location.search,
            linkEntry: linkFirebaseEntry,
        });
    }
}

function readConfig() {
    const request = new XMLHttpRequest();
    request.open('GET', '/config/app.env', false);
    request.send();
    if (request.status === 200) {
        const config = {};
        request.responseText
            .split('\n')
            .map(item => item.trim())
            .filter(item => item[0] !== '#')
            .forEach(item => {
                const [name, value] = item.split('=', 2);
                config[name] = value;
            });
        console.log('config', config);
        return config;
    }
    throw Error('Not found application config');
}

const config = readConfig();
detectFramework(config);
